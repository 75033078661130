<template>
  <div id="table-form" class="mb-3 p-2">
    <h5>3ตัว/2ตัว/เลขวิ่ง</h5>

    <table ref="tableBet" class="table table-bordered table-sm table-bet mb-0">
      <thead class="thead-light">
        <tr>
          <th class="text-center">
            หมายเลข
            <div class="btn-link cursor-pointer" @click="toggleSpin">
              (กลับเลข)
            </div>
          </th>
          <th class="text-center">
            บน
            <div class="btn-link cursor-pointer" @click="copyAmountTop">
              (คัดลอก)
            </div>
          </th>
          <th class="text-center">
            ล่าง
            <div class="btn-link cursor-pointer" @click="copyAmountBot">
              (คัดลอก)
            </div>
          </th>
          <th class="text-center">
            โต๊ด
            <div class="btn-link cursor-pointer" @click="copyAmountTod">
              (คัดลอก)
            </div>
          </th>
          <th class="text-center">ลบ</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in tableNumbers" :key="index">
          <td>
            <b-form-input
              type="text"
              size="sm"
              class="text-center form-control input-number keypress"
              maxlength="3"
              v-model="row.number"
              v-on:blur="checkNumber($event)"
              v-on:keydown="keypress"
            />
          </td>
          <td class="text-center">
            <b-form-input
              type="text"
              size="sm"
              class="text-center form-control keypress"
              v-model="row.amountTop"
              :number="true"
              v-on:keydown="keypress"
              v-on:blur="checkAmountTop($event, row)"
              :disabled="isDisabled('top', row.number)"
            />
          </td>
          <td class="text-center">
            <b-form-input
              type="text"
              size="sm"
              class="text-center form-control keypress"
              v-model="row.amountBot"
              :number="true"
              v-on:keydown="keypress"
              v-on:blur="checkAmountBot($event, row)"
              :disabled="isDisabled('bot', row.number)"
            />
          </td>
          <td class="text-center">
            <b-form-input
              type="text"
              size="sm"
              class="text-center form-control keypress"
              v-model="row.amountTod"
              :number="true"
              v-on:keydown="keypress"
              v-on:blur="checkAmountTod($event, row)"
              :disabled="isDisabled('tod', row.number)"
            />
          </td>
          <td class="text-center">
            <i v-if="tableNumbers.length > 1" class="far fa-trash-alt text-danger bigger-175 cursor-pointer" @click="removeRow(index)"></i>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'TableForm',
  props: ['tableNumbers'],
  data() {
    return {}
  },
  computed: {
    rate() {
      return this.$store.state.round.rate
    },
    isOpenThreeTop() {
      return this.rate?.openBets.threeNumberTop.isAvailable || false
    },
    isOpenThreeTod() {
      return this.rate?.openBets.threeNumberTode.isAvailable || false
    },
    isOpenTwoTop() {
      return this.rate?.openBets.twoNumberTop.isAvailable || false
    },
    isOpenTwoBot() {
      return this.rate?.openBets.twoNumberBottom.isAvailable || false
    },
    isOpenRunTop() {
      return this.rate?.openBets.runTop.isAvailable || false
    },
    isOpenRunBot() {
      return this.rate?.openBets.runBottom.isAvailable || false
    }
  },
  watch: {
    tableNumbers(n, o) {
      if(n.length === 0) {
        this.addRow()
      }
    }
  },
  methods: {
    addRow() {
      this.tableNumbers.push({
        number: '',
        amountTop: '',
        amountBot: '',
        amountTod: '',
        reversed: false
      });
    },
    toggleSpin() {
      this.tableNumbers.splice(this.tableNumbers.length-1, 1)
      this.tableNumbers.forEach((n, index)=>{
        if(n.reversed || !n.number.length)
          return

        const newNumbers = this.spinNumbers(n.number)
        newNumbers.forEach((newNumber)=>{
          if(newNumber !== n.number) {
            this.tableNumbers.push({
              number: newNumber,
              amountTop: '',
              amountBot: '',
              amountTod: '',
              reversed: true
            })
          }
        })

        this.tableNumbers[index].reversed = true
      })
      this.addRow()
    },
    spinNumbers(number) {
      const numbers = []

      const n = number.split('')
      if(n.length === 2) {
        numbers.push(n[0] + n[1])
        numbers.push(n[1] + n[0])
      }else
      if(n.length === 3){
        numbers.push(n[0] + n[1] + n[2])
        numbers.push(n[0] + n[2] + n[1])
        numbers.push(n[1] + n[0] + n[2])
        numbers.push(n[1] + n[2] + n[0])
        numbers.push(n[2] + n[0] + n[1])
        numbers.push(n[2] + n[1] + n[0])
      }

      return _.uniq(numbers)
    },
    copyAmountTop() {
      let keepAmount = ''
      this.tableNumbers.forEach((n, index)=>{
        if(n.amountTop) {
          keepAmount = n.amountTop
        }else
        if(n.number.length){
          this.tableNumbers[index].amountTop = keepAmount
        }
      })
    },
    copyAmountBot() {
      let keepAmount = ''
      this.tableNumbers.forEach((n, index)=>{
        if(n.amountBot) {
          keepAmount = n.amountBot
        }else
        if(n.number.length){
          this.tableNumbers[index].amountBot = keepAmount
        }
      })
    },
    copyAmountTod() {
      let keepAmount = ''
      this.tableNumbers.forEach((n, index)=>{
        if(n.amountTod) {
          keepAmount = n.amountTod
        }else
        if(n.number.length){
          this.tableNumbers[index].amountTod = keepAmount
        }
      })
    },
    checkNumber(event) {
      if(event.target.value) {

        const elements = this.$refs.tableBet.getElementsByClassName('input-number')
        const focusable = []
        elements.forEach((el)=>{
          focusable.push(el)
        })
        const cIndex = focusable.findIndex(el=>el===event.target)

        // มีแถวเดียว หรือเป็นแถวสุดท้าย
        if (focusable.length === 1 || cIndex === (focusable.length - 1)) {
          this.addRow()
        }
      }
    },
    isDisabled(pos, number) {
      if(!number)
        return true

      if(pos === 'top') {
        return {
          '1': !this.isOpenRunTop,
          '2': !this.isOpenTwoTop,
          '3': !this.isOpenThreeTop
        }[number.length]
      }else
      if(pos === 'bot') {
        return {
          '1': !this.isOpenRunBot,
          '2': !this.isOpenTwoBot,
          '3': true
        }[number.length]
      }else
      if(pos === 'tod') {
        return {
          '1': true,
          '2': true,
          '3': !this.isOpenThreeTod
        }[number.length]
      }else{
        return true
      }
    },
    keypress(event) {

      const elements = this.$refs.tableBet.getElementsByClassName('keypress')
      const focusable = []
      elements.forEach((el)=>{
        if(!el.disabled)
          focusable.push(el)
      })
      const cIndex = focusable.findIndex(el=>el===event.target)

      if(['Enter', 'ArrowRight', 'ArrowDown', 'Tab'].includes(event.key)) {
        /**
         * ไปข้างหน้า
         */
        const nIndex = cIndex + 1
        if(nIndex < focusable.length) {
          focusable[nIndex].focus()
          event.preventDefault()
        }
      }else
      if(['ArrowLeft', 'ArrowUp'].includes(event.key)) {
        /**
         * ย้อนกลับ
         */
        const nIndex = cIndex - 1
        if(nIndex >= 0) {
          focusable[nIndex].focus()
          event.preventDefault()
        }
      }else
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'].includes(event.key)) {
        /**
         * ไม่ใช่ปุ่มตัวเลข และปุ่มลบ
         */
        event.preventDefault()
      }
    },
    removeRow(index) {

      this.tableNumbers.splice(index, 1)

      if(this.tableNumbers.length === 0)
        this.addRow()
    },
    checkAmountTop(event, row) {

      // if(row.number.length === 1) {
      //   const max = this.rate.betLimit.runTop.max
      //   if(row.amountTop > max) {
      //     row.amountTop = max
      //   }
      // }else
      // if(row.number.length === 2) {
      //   const max = this.rate.betLimit.twoNumberTop.max
      //   if(row.amountTop > max) {
      //     row.amountTop = max
      //   }
      // }else
      // if(row.number.length === 3) {
      //   const max = this.rate.betLimit.threeNumberTop.max
      //   if(row.amountTop > max) {
      //     row.amountTop = max
      //   }
      // }
    },
    checkAmountBot(event, row) {

      // if(row.number.length === 1) {
      //   const max = this.rate.betLimit.runBottom.max
      //   if(row.amountBot > max) {
      //     row.amountBot = max
      //   }
      // }else
      // if(row.number.length === 2) {
      //   const max = this.rate.betLimit.twoNumberBottom.max
      //   if(row.amountBot > max) {
      //     row.amountBot = max
      //   }
      // }
    },
    checkAmountTod(event, row) {

      // if(row.number.length === 3) {
      //   const max = this.rate.betLimit.threeNumberTode.max
      //   if(row.amountTod > max) {
      //     row.amountTod = max
      //   }
      // }
    }
  },
  mounted() {
    if(this.tableNumbers.length === 0)
      this.addRow()

    const elements = this.$refs.tableBet.getElementsByClassName('input-number')
    setTimeout(()=>{
      elements[elements.length-1].focus()
    }, 500)
  }
}
</script>
<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
  font-size: 90%;
}
#table-form {
  .table-bet {
    &.table-bordered {
      th, td {
        border: 1px solid #777;
        vertical-align: middle;
      }
    }
  }
}
</style>
