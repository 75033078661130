<template>
  <div class="position-relative">
    <div class="card alert-primary border-primary mb-3">
      <div class="card-body p-3">
        <h5 class="d-flex justify-content-between">
          <span>
            <span class="text-blue">[{{roundGroupName}}]</span>
            <span class="text-blue"> {{roundMarketName}}</span>
          </span>
          <span>{{roundDate}}</span>
        </h5>
        <div class="form-inline mt-2 d-flex justify-content-between">
          <div>
            <span class="my-1 mr-2">อัตราจ่าย:</span>
            <span>{{rateTitle}}</span>
          </div>
          <div v-if="roundIcon" class="float-right">
            <img :src="roundIcon" style="width: 60px;">
          </div>
        </div>
      </div>
    </div>

    <h5>กรุณายืนยันรายการ</h5>
    <div class="mb-3">
      <table class="table table-bordered table-sm">
        <thead>
          <tr class="text-center">
            <th>ประเภท</th>
            <th>หมายเลข</th>
            <th>ยอดเดิมพัน</th>
            <th>เรทจ่าย</th>
            <th>ส่วนลด</th>
            <th>หมายเหตุ</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in ticket.rows" :key="index" :class="rowAlertCSS(row)">
            <td class="text-center"><span>{{ row.text }}</span></td>
            <td class="text-center"><span class="text-primary">{{ row.number }}</span></td>
            <td class="text-right">{{ row.amount | amountFormat(2, '0.00') }}</td>
            <td class="text-right"><span>{{ row.pay | amountFormat(2, '0.00') }}</span></td>
            <td class="text-right"><span>{{ row.discount | amountFormat(2, '0.00') }}</span></td>
            <td class="text-center small">{{ row.note }}</td>
            <td width="35" class="text-center"><span class="btn-delete cursor-pointer" style="cursor: pointer;" @click="removeItem(row)"><i class="far fa-trash-alt text-danger bigger-175"></i></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="invalidItems.length">
      <h6 class="text-danger">***แจ้งเตือน***</h6>
      <div class="mb-3">
        <table class="table table-invalid table-bordered table-sm table-danger">
          <thead class="bg-danger">
            <tr class="text-center">
              <th width="25%">ประเภท</th>
              <th width="20%">หมายเลข</th>
              <th colspan="2">หมายเหตุ</th>
            </tr>
          </thead>
          <tbody>
            <tr class="alert-danger" v-for="(row, index) in invalidItems" :key="'invalid-'+index">
              <td class="text-center">{{ row.text }}</td>
              <td class="text-center">{{ row.number }}</td>
              <td class="text-center">{{ row.note }}</td>
              <td width="50" class="text-center">
                <span class="btn-delete cursor-pointer" style="cursor: pointer;" @click="removeItem(row)"><i class="far fa-trash-alt text-danger bigger-175"></i></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <h5 class="text-center">
      ยอดเดิมพัน <span> {{ total.amount | amountFormat(2, '0.00') }} </span> บาท
    </h5>
    <h5 class="text-center">
      ส่วนลด <span class="text-danger"> {{ total.discount | amountFormat(2, '0.00') }} </span> บาท
    </h5>
    <h5 class="text-center">
      รวม <span class="text-primary"> {{ total.credit | amountFormat(2, '0.00') }} </span> บาท
    </h5>
    <p class="text-center">หมายเหตุ: {{ ticket.remarks }}</p>

    <div id="btn-group-actions" class="row justify-content-center">
      <div class="col-3 pr-1">
        <button class="btn btn-block btn-danger" type="button" @click="cancel">ยกเลิก</button>
      </div>
      <div class="col-3 px-1">
        <button class="btn btn-block btn-dark" type="button" @click="back">ย้อนกลับ</button>
      </div>
      <div class="col-3 pl-1">
        <button id="confirm-btn" class="btn btn-primary btn-block" type="button" @click="confirm">ยืนยัน</button>
      </div>
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="60"
      :width="60"
      color="#007bff"
      loader="dots"
    />
  </div>
</template>
<script>
import moment from '@/helpers/moment'
import Swal from 'sweetalert2'
import LotteryService from '@/services/LotteryService'
import cAlert from '@/helpers/alert'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import cryptoRandomString from 'crypto-random-string'

import { database } from '@/firebaseConfig'
import { ref, onValue, onChildAdded, onChildChanged, onChildRemoved, off, remove } from 'firebase/database'

export default {
  name: 'RoundTicket',
  components: {
    Loading
  },
  props: ['round', 'ticket'],
  data() {
    return {
      isLoading: false,
      invalidItems: [],
      queueId: null,
      rtdbRef: null
    }
  },
  computed: {
    balance() {
      return this.$store.state.account.balance || 0
    },
    roundGroupName() {
      if(!this.round)
        return ''

      return this.round?.note.groupTitle
    },
    roundMarketName() {
      if(!this.round)
        return ''

      return this.round?.note.marketTitle
    },
    roundDate() {
      if(!this.round)
        return ''

      return moment(this.round.roundDate.date).format("DD-MM-YYYY")
    },
    roundIcon() {
      if(!this.round)
        return false

      return this.round?.market?.imageIcon || false
    },
    rate() {
      return this.$store.state.round.rate
    },
    rateTitle() {
      return this.rate?.rateTitle || ''
    },
    total() {
      const total = this.ticket.rows.reduce((total, row)=>{
        total.amount += row.amount
        total.discount += row.discount
        total.credit += row.credit
        return total
      }, {
        amount: 0,
        discount: 0,
        credit: 0
      })
      return total
    }
  },
  methods: {
    removeItem(item) {
      this.invalidItems = this.invalidItems.filter((i)=>{
        return item.uid !== i.uid
      })

      this.ticket.rows = this.ticket.rows.filter((i)=>{
        return item.uid !== i.uid
      })
    },
    cancel() {
      this.$emit('cancel')
    },
    back() {
      this.$emit('back')
    },
    confirm() {

      /**
       * ไม่มีรายการแทง
       */
      if(this.ticket.rows.length === 0) {
        return Swal.fire({
          text: 'ไม่มีรายการแทงเลข',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      /**
       * ตรวจสอบยอดเดิมพัน
       */
      const invalidRows = this.ticket.rows.filter(row=>!row.isValid)
      if(invalidRows.length > 0) {
        return Swal.fire({
          text: 'กรุณาตรวจสอบรายการเดิมพันให้ถูกต้อง',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      /**
       * ตรวจสอบรายการเดิมพันที่ถูกต้อง
       */
      const validRows = this.ticket.rows.filter(row=>row.isValid && !row.needConfirm)
      if(!validRows.length) {
        return Swal.fire({
          text: 'ไม่มีเลขที่สามารถทำรายการได้',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      /**
       * ตรวจสอบยอดเงินในบัญชี
       */
      if(this.total.credit > this.balance) {
        const balance = this.balance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        this.$store.dispatch('reloadBalance')
        return Swal.fire({
          title: 'ยอดเงินในบัญชีไม่พอ',
          text: `ยอดเงินคงเหลือ ${balance}`,
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      /**
       * ตรวจสอบเลขปิดรับ
       */
      const needConfirmNumbers = this.ticket.rows.filter(row=>row.needConfirm)
      if(needConfirmNumbers.length > 0) {
        return this.needConfirmAlert(needConfirmNumbers)
      }

      this.saveTicket(false)
    },
    needConfirmAlert(numbers) {
      const htmlConfirmNumbers = numbers.map((row)=>{
        return `<tr>
          <td>${row.text}</td>
          <td class="text-primary">${row.number}</td>
          <td class="text-danger">${row.note}</td>
        </tr>`
      })

      Swal.fire({
        title: 'แจ้งเตือน',
        html: `<div class="text-info mb-2">มีรายการเลขปิดรับ หรือปรับราคาจ่าย<br />ต้องการทำรายการต่อหรือไม่?</div>
        <table class="table table-sm table-warning table-bordered mb-0">
          <thead class="small">
            <tr>
              <th width="20%">ประเภท</th>
              <th width="20%">หมายเลข</th>
              <th>รายละเอียด</th>
            </tr>
          </thead>
          <tbody class="small bg-light">${htmlConfirmNumbers.join('')}</tbody>
        </table>`,
        icon: 'warning',
        confirmButtonText: 'ยืนยันการแทง',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        reverseButtons: true,
        confirmButtonColor: "#007bff",
        customClass: {
          confirmButton: 'btn',
          cancelButton: 'btn btn-secondary'
        }
      })
      .then((result)=>{
        this.removeRtdb()
        if(result.isConfirmed) {
          this.saveTicket(true)
        }else{
          this.closeLoading()
        }
      })
    },
    saveTicket(withConfirm=false) {
      this.isLoading = true
      this.$store.commit('updateProcessTicketStatus', true)
      this.ticket.queueId = this.queueId
      this.invalidItems = []
      LotteryService.createTicket({...this.ticket, withConfirm})
      .then((response)=>{
        if(response.success) {
          this.queueId = response.data.queueId

          if(response.data.status === 'Complete') {
            this.$emit('success')
            this.closeLoading()
          }else{
            Swal.fire({
              title: 'ได้รับโพยแล้ว!',
              icon: 'warning',
              html: `กำลังตรวจสอบ กรุณารอซักครู่...`,
              didOpen: () => {
                Swal.showLoading()
              },
              willClose: () => {
                console.log('close!!!')
              },
              allowOutsideClick: false,
              showConfirmButton: true,
              confirmButtonText: 'ตรวจสอบสถานะ'
            })
            this.checkStatus()
          }
        }else{
          throw new Error(response)
        }
      })
      .catch((error)=>{
        this.alertTicketError(error)
      })
    },
    checkStatus() {
      this.rtdbRef = ref(database, `${process.env.VUE_APP_LOTTO_FIREBASE_RTD_DATABASE_PATH}/${this.queueId}`)

      onValue(this.rtdbRef, async (snapshot) => {
        try {
          const data = snapshot.val()
          if(data?.status === 'Complete') {
            this.removeRtdb()
            this.$emit('success')
            this.closeLoading()
          }else
          if(data?.status === 'Error'){
            throw data
          }
        }
        catch(error) {
          this.alertTicketError(error)
        }
      }, (error) => {
        this.alertTicketError(error)
      });
    },
    closeLoading() {
      this.isLoading = false
      this.$store.commit('updateProcessTicketStatus', false)
      Swal.close()
      this.stopRtdb();
    },
    mapServData() {
      const needConfirmNumbers = this.invalidItems.filter(row=>row.needConfirm && row.isValid)
      if(needConfirmNumbers.length > 0) {

        /**
         * map ค่าที่ได้จาก server
         */
        this.ticket.rows = this.ticket.rows.map((i)=>{

          const servValue = needConfirmNumbers.find((s)=>{
            // return (i.amount === s.amount && i.number === s.number && i.type === s.type)
            return i.uid === s.uid
          })

          // ไม่รับยอด ปรับเป็น 0
          if(servValue?.confirmCode === 'NumberClosed' || servValue?.confirmCode === 'NumberSold') {
            return {
              ...i,
              ...servValue,
              pay: 0,
              amount: 0,
              credit: 0,
              discount: 0
            }
          }else
          if(servValue?.confirmCode === 'RateChanged'){
            return {
              ...i,
              ...servValue
            }
          }else{
            return i
          }
        })

        /**
         * มีเลขปิดรับเพิ่มเติมหรือไม่
         */
        const isInvalidSoldItems = needConfirmNumbers.some((row)=>{
          return row.confirmCode === 'NumberSold'
        })

        if(isInvalidSoldItems) {
          this.$emit('reloadSoldout')
        }

        this.needConfirmAlert(needConfirmNumbers)
      }else{
        this.alertTicketError(null)
      }
    },
    async removeRtdb() {
      if (this.rtdbRef) {
        await remove(this.rtdbRef);
        console.log('Remove error data');
      }
    },
    stopRtdb() {
      if (this.rtdbRef) {
        off(this.rtdbRef);
        console.log('Stopped listening to changes');
      }
    },
    alertTicketError(data) {
      /**
       * สร้าง Unique Code ใหม่
       */
      const newUniqueCode = cryptoRandomString({length: 24, type: 'alphanumeric'})
      this.ticket.uniqueCode = `${this.round._id}-${newUniqueCode}`
      /**
       * รายการเลขไม่ถูกต้อง
       */
      if(data?.name === 'TicketItemException') {
        this.invalidItems = data?.errors?.[0]?.data || []
        this.mapServData(data?.message)
      }else{
        cAlert({
          title: 'ผิดพลาด!',
          text: data?.message || 'ส่งโพยไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        }, ()=>{
          this.closeLoading()
          /**
           * error code 501 = ให้ redirect ไปหน้ารวมหวย
           */
          if(data?.code === 501) {
            this.$router.push({name: 'PlayLotto'})
          }
        })
      }
    },
    rowAlertCSS(item) {
      if(item.amount === 0) {
        return 'alert-danger'
      }

      if(!item.isValid || item.needConfirm || item.isWarning)
        return 'alert-warning'

      return ''
    }
  },
  beforeDestroy() {
    this.stopRtdb();
  },
  mounted() {
    setTimeout(()=>{
      document.getElementById('btn-group-actions').scrollIntoView()
      document.getElementById('confirm-btn').focus()
    }, 100)
  }
}
</script>
<style lang="scss" scoped>
.table {
  font-size: 90%;
  background-color: #FFF;

  thead {
    background-color: #17a2b8;

    th {
      font-weight: normal !important;
      border: 1px solid #17a2b8;
      color: #FFFFFF;
    }
  }

  tbody {
    tr {
      td {
        vertical-align: middle;
        border: 1px solid #17a2b8;
      }
    }
  }
}

.table-invalid {
  border: 1px solid #dc3545 !important;

  thead {
    th, td {
      border: 1px solid #dc3545 !important;
    }
  }

  tbody {
    th, td {
      border: 1px solid #dc3545 !important;
    }
  }
}
</style>
