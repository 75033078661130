<template>
  <div>
    <span style="color: #205295; font-weight: normal;"><i class="fas fa-hand-holding-usd"></i> อัตราจ่าย</span>
    <table class="table table-bordered table-sm mb-0">
      <thead>
        <tr>
          <th class="text-center">
            <div>{{marketTitle}}<br> ({{rateTitle}})</div>
          </th>
          <th width="23%" class="text-center">
            <div>จ่าย (บาท)</div>
          </th>
          <th width="23%" class="text-center">
            <div>ลด (%)</div>
          </th>
          <th width="23%" class="text-center">
            <div>ขั้นต่ำ (บาท)</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr :class="{'text-dark': !isAvailable('threeNumberTop')}">
          <td class="text-center">3 ตัวบน</td>
          <td class="text-center">{{ pay('threeNumberTop') | amountFormat }}</td>
          <td class="text-center">{{ discount('threeNumberTop') | amountFormat }}</td>
          <td class="text-center">{{ limit('threeNumberTop') }}</td>
        </tr>
        <tr :class="{'text-dark': !isAvailable('threeNumberTode')}">
          <td class="text-center">3 ตัวโต๊ด</td>
          <td class="text-center">{{ pay('threeNumberTode') | amountFormat }}</td>
          <td class="text-center">{{ discount('threeNumberTode') | amountFormat }}</td>
          <td class="text-center">{{ limit('threeNumberTode') }}</td>
        </tr>
        <tr :class="{'text-dark': !isAvailable('twoNumberTop')}">
          <td class="text-center">2 ตัวบน</td>
          <td class="text-center">{{ pay('twoNumberTop') | amountFormat }}</td>
          <td class="text-center">{{ discount('twoNumberTop') | amountFormat }}</td>
          <td class="text-center">{{ limit('twoNumberTop') }}</td>
        </tr>
        <tr :class="{'text-dark': !isAvailable('twoNumberBottom')}">
          <td class="text-center">2 ตัวล่าง</td>
          <td class="text-center">{{ pay('twoNumberBottom') | amountFormat }}</td>
          <td class="text-center">{{ discount('twoNumberBottom') | amountFormat }}</td>
          <td class="text-center">{{ limit('twoNumberBottom') }}</td>
        </tr>
        <tr :class="{'text-dark': !isAvailable('runTop')}">
          <td class="text-center">วิ่งบน</td>
          <td class="text-center">{{ pay('runTop') | amountFormat }}</td>
          <td class="text-center">{{ discount('runTop') | amountFormat }}</td>
          <td class="text-center">{{ limit('runTop') }}</td>
        </tr>
        <tr :class="{'text-dark': !isAvailable('runBottom')}">
          <td class="text-center">วิ่งล่าง</td>
          <td class="text-center">{{ pay('runBottom') | amountFormat }}</td>
          <td class="text-center">{{ discount('runBottom') | amountFormat }}</td>
          <td class="text-center">{{ limit('runBottom') }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'RoundPayrate',
  data() {
    return {}
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    isShow() {
      return this.currentRouteName === 'PlayRoundId' && this.rate
    },
    groupTitle() {
      return this.$store.state?.round?.groupTitle
    },
    marketTitle() {
      return this.$store.state?.round?.marketTitle
    },
    rate() {
      return this.$store.state.round.rate
    },
    rateTitle() {
      return this.rate?.rateTitle || ''
    }
  },
  methods: {
    isAvailable(type) {
      return this.rate.openBets[type]?.isAvailable
    },
    pay(type) {
      return (this.rate.openBets[type]?.pay || 0)
    },
    discount(type) {
      return (this.rate.openBets[type]?.discount || 0)
    },
    min(type) {
      return (this.rate.betLimit[type]?.min || 0)
    },
    max(type) {
      return (this.rate.betLimit[type]?.max || 0)
    },
    limit(type) {
      return `${this.min(type)} - ${this.max(type)}`
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    background-color: #2C74B3;

    tr {
      th {
        font-size: 85%;
        text-align: center;
        border: 1px solid #2C74B3;
        color: #FFFFFF;
        font-weight: normal;
      }
    }
  }

  tr {
    td {
      font-size: 85%;
      vertical-align: middle;
      text-align: center;
      border: 1px solid #2C74B3;
    }
  }
}
</style>
