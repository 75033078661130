import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyANGgXVLFGAroBDyID9b8cn0Ravcr31Lfk",
  authDomain: "gameproject-371ad.firebaseapp.com",
  databaseURL: "https://gameproject-371ad-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "gameproject-371ad",
  storageBucket: "gameproject-371ad.appspot.com",
  messagingSenderId: "1086080234630",
  appId: "1:1086080234630:web:ba022b1345be78d7934d07",
  measurementId: "G-3MZGJWM2ZH"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };
