<template>
  <div>
    <div class="row mt-3">
      <div class="col-12 mt-2">
        <b-form-group class="mb-1" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            v-model="winType"
            :aria-describedby="ariaDescribedby"
          >
            <b-form-radio value="two">จับวิน 2 ตัว</b-form-radio>
            <b-form-radio value="three">จับวิน 3 ตัว</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <b-form-group class="mb-1" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            v-model="incloudDouble"
            :aria-describedby="ariaDescribedby"
          >
            <b-form-radio :value="false">จับวินไม่รวมเลขเบิ้ล</b-form-radio>
            <b-form-radio :value="true">จับวินรวมเลขเบิ้ล</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <hr class="my-1">
      </div>
      <div class="col-12">
        <span class="d-block pb-1">กรุณาเลือกตัวเลขที่ต้องการจับวิน 2 - 7 ตัวเลข </span>
        <div class="d-flex">
          <span class="win-input-number alert-warning" @click="toggleNumber('1')" :class="{'active': isSelected('1')}">1</span>
          <span class="win-input-number alert-warning" @click="toggleNumber('2')" :class="{'active': isSelected('2')}">2</span>
          <span class="win-input-number alert-warning" @click="toggleNumber('3')" :class="{'active': isSelected('3')}">3</span>
          <span class="win-input-number alert-warning" @click="toggleNumber('4')" :class="{'active': isSelected('4')}">4</span>
          <span class="win-input-number alert-warning" @click="toggleNumber('5')" :class="{'active': isSelected('5')}">5</span>
        </div>
        <div class="d-flex">
          <span class="win-input-number alert-warning" @click="toggleNumber('6')" :class="{'active': isSelected('6')}">6</span>
          <span class="win-input-number alert-warning" @click="toggleNumber('7')" :class="{'active': isSelected('7')}">7</span>
          <span class="win-input-number alert-warning" @click="toggleNumber('8')" :class="{'active': isSelected('8')}">8</span>
          <span class="win-input-number alert-warning" @click="toggleNumber('9')" :class="{'active': isSelected('9')}">9</span>
          <span class="win-input-number alert-warning" @click="toggleNumber('0')" :class="{'active': isSelected('0')}">0</span>
        </div>
        <div class="my-2">
          <button type="button" class="btn border border-dark btn-light" @click="toggleGenerate">คำนวน</button>
          <button type="button" class="btn border border-dark btn-light ml-2" @click="toggleSpin">กลับเลขวิน</button>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <button class="btn mb-2 mx-1 btn-info" v-for="(number, index) in inputNumbers" @click="toggleRemove(index)">{{number}}</button>
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-danger btn-sm" @click="clear"><i class="fas fa-trash-alt mr-1"></i>ยกเลิก</button><br />
        <button type="button" class="btn btn-primary btn-sm mt-1" @click="uniqueNumbers"><i class="fas fa-ban mr-1"></i>ลบเลขซ้ำ</button>
      </div>
    </div>

    <div ref="winNumber" class="form-group row mt-1 mb-3">
      <div class="col pr-1 text-center">
        <label for="" class="small mb-1">บน</label>
        <b-form-input
          type="text"
          class="text-center form-control express"
          name="amountTop"
          v-on:keydown="keypress($event, 'top')"
          v-model="amountTop"
          :disabled="!isOpenTop"
          :placeholder="!isOpenTop?'ไม่เปิดแทง':''"
          :number="true"
        />
      </div>
      <div class="col px-1 text-center">
        <label for="" class="small mb-1">{{ winType==='two' ? 'ล่าง' : 'โต๊ด' }}</label>
        <b-form-input
          type="text"
          class="text-center form-control express"
          name="amountBot"
          v-on:keydown="keypress($event, 'bot')"
          v-model="amountBot"
          :disabled="!isOpenBot"
          :placeholder="!isOpenBot?'ไม่เปิดแทง':''"
          :number="true"
        />
      </div>
      <div class="col-auto pl-1 mt-2 text-center align-self-end">
        <button type="button" class="btn btn-info express" v-on:keydown="keypress" @click="addBill"><i class="fas fa-plus mt-1"></i> เพิ่มบิล</button>
      </div>
    </div>

  </div>
</template>
<script>
import Swal from 'sweetalert2'
import _ from 'lodash'

export default {
  name: 'WinNumber',
  data() {
    return {
      elements: null,
      winType: 'two',
      incloudDouble: false,
      selectNumbers: [],
      inputNumbers: [],
      amountTop: null,
      amountBot: null
    }
  },
  computed: {
    toggleChanged() {
      return {
        winType: this.winType,
        incloudDouble: this.incloudDouble
      }
    },
    rate() {
      return this.$store.state.round.rate
    },
    isOpenThreeTop() {
      return this.rate?.openBets.threeNumberTop.isAvailable || false
    },
    isOpenThreeTod() {
      return this.rate?.openBets.threeNumberTode.isAvailable || false
    },
    isOpenTwoTop() {
      return this.rate?.openBets.twoNumberTop.isAvailable || false
    },
    isOpenTwoBot() {
      return this.rate?.openBets.twoNumberBottom.isAvailable || false
    },
    isOpenRunTop() {
      return this.rate?.openBets.runTop.isAvailable || false
    },
    isOpenRunBot() {
      return this.rate?.openBets.runBottom.isAvailable || false
    },
    isOpenTop() {
      if(this.winType === 'two') {
        return this.isOpenTwoTop
      }else
      if(this.winType === 'three') {
        return this.isOpenThreeTop
      }
    },
    isOpenBot() {
      if(this.winType === 'two') {
        return this.isOpenTwoBot
      }else
      if(this.winType === 'three') {
        return this.isOpenThreeTod
      }
    }
  },
  watch: {
    toggleChanged() {
      this.clear()
    }
  },
  methods: {
    toggleNumber(num) {
      if(this.selectNumbers.includes(num)) {
        this.selectNumbers = this.selectNumbers.filter((n)=>{
          return n !== num
        })
      }else{
        this.selectNumbers.push(num)
      }
    },
    isSelected(num) {
      return this.selectNumbers.includes(num)
    },
    toggleGenerate() {
      if(this.selectNumbers.length < 2 || this.selectNumbers.length > 7) {
        return Swal.fire({
          text: 'กรุณาเลือกตัวเลขที่ต้องการจับวิน 2 - 7 ตัวเลข',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
      }else
      if(this.winType === 'two') {
        this.genTwoNumbers()
        this.focusInputAmount()
      }else
      if(this.winType === 'three') {
        this.genThreeNumbers()
        this.focusInputAmount()
      }
    },
    genTwoNumbers() {
      this.inputNumbers = []
      for(let i=0; i<this.selectNumbers.length; i++) {
        for(let j=i; j<this.selectNumbers.length; j++) {
          if(this.incloudDouble || i !== j) {
            this.inputNumbers.push(`${this.selectNumbers[i]}${this.selectNumbers[j]}`)
          }
        }
      }
    },
    genThreeNumbers() {
      this.inputNumbers = []
      for(let i=0; i<this.selectNumbers.length; i++) {
        for(let j=i; j<this.selectNumbers.length; j++) {
          for(let k=j; k<this.selectNumbers.length; k++) {
            if(this.incloudDouble || (i!==j && i!==k && j!==k)) {
              this.inputNumbers.push(`${this.selectNumbers[i]}${this.selectNumbers[j]}${this.selectNumbers[k]}`)
            }
          }
        }
      }
    },
    toggleSpin() {
      if(this.winType === 'two') {
        this.inputNumbers = this.inputNumbers.reduce((ns, num)=>{
          const n = num.split('')
          return ns.concat(_.uniq([
            `${n[0]}${n[1]}`,
            `${n[1]}${n[0]}`
          ]))
        }, [])
      }else
      if(this.winType === 'three') {
        this.inputNumbers = this.inputNumbers.reduce((ns, num)=>{
          const n = num.split('')
          return ns.concat(_.uniq([
            `${n[0]}${n[1]}${n[2]}`,
            `${n[0]}${n[2]}${n[1]}`,
            `${n[1]}${n[0]}${n[2]}`,
            `${n[1]}${n[2]}${n[0]}`,
            `${n[2]}${n[0]}${n[1]}`,
            `${n[2]}${n[1]}${n[0]}`
          ]))
        }, [])
      }
      this.focusInputAmount()
    },
    uniqueNumbers() {
      this.inputNumbers = _.uniq(this.inputNumbers)
    },
    keypress(event, pos) {

      const focusable = this.elements
      const els = []
      focusable.forEach((el)=>{
        if(!el.disabled)
          els.push(el)
      })

      const cIndex = els.findIndex(el=>el===event.target)
      /**
       * กด Tab ในช่อง "บน" ให้ใช้ฟังก์ชั่น addBill
       */
      if(event.key === 'Tab' && pos === 'bot') {
        event.preventDefault()
        this.addBill()
      }else // ไปข้างหน้า
      if(['Enter', 'ArrowRight', 'ArrowDown', 'Tab'].includes(event.key)) {
        const nIndex = cIndex + 1
        if(nIndex < els.length) {
          els[nIndex].focus()
          event.preventDefault()
        }
      }else // ย้อนกลับ
      if(['ArrowLeft', 'ArrowUp'].includes(event.key)) {
        const nIndex = cIndex - 1
        if(nIndex >= 0) {
          els[nIndex].focus()
          event.preventDefault()
        }
      }else
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Control', 'v', 'V'].includes(event.key)) {
        event.preventDefault()
      }
    },
    clear() {
      this.amountTop = ''
      this.amountBot = ''
      this.selectNumbers = []
      this.inputNumbers = []
    },
    toggleRemove(index) {
      this.inputNumbers.splice(index, 1)
    },
    addBill() {

      /**
       * ยังไม่กรอกเลข
       */
      if(this.inputNumbers.length === 0)
        return Swal.fire({
          text: 'กรุณาใส่เลขที่จะเล่น',
          icon: 'warning',
          confirmButtonText: 'OK'
        })

      /**
       * ไม่ใส่ราคา
       */
      if(!this.amountTop && !this.amountBot)
        return Swal.fire({
          text: 'กรุณาใส่ยอดเงินที่จะเล่น',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
        .then(()=>{
          this.focusInputAmount()
        })

      const amountTop = (this.amountTop || 0)
      const amountBot = (this.amountBot || 0)
      const billAmount = this.inputNumbers.reduce((total, number)=>{
        total += (amountTop + amountBot)
        return total
      }, 0)

      /**
       * เลข 2 ตัว
       */
      if(this.winType === 'two') {
        const bill = {
          type: 'twoNumber',
          text: {
            title: '2 ตัว',
            description: 'บน x ล่าง',
            amount: `${amountTop} x ${amountBot}`
          },
          numbers: this.inputNumbers,
          amountTop: amountTop,
          amountBot: amountBot,
          billAmount: billAmount
        }
        this.$emit('addBill', bill)
      }else
      /**
       * เลข 3 ตัว
       */
      if(this.winType === 'three') {
        const bill = {
          type: 'threeNumber',
          text: {
            title: '3 ตัว',
            description: 'บน x โต๊ด',
            amount: `${amountTop} x ${amountBot}`
          },
          numbers: this.inputNumbers,
          amountTop: amountTop,
          amountTod: amountBot,
          billAmount: billAmount
        }
        console.log('bill', bill)
        this.$emit('addBill', bill)
      }

      /**
       * reset ค่า
       */
      this.clear()
      this.focusInputAmount()
    },
    focusInputAmount() {
      setTimeout(()=>{
        this.elements[0].focus()
      }, 500)
    }
  },
  mounted() {
    this.elements = this.$refs.winNumber.getElementsByClassName('express')
  }
}
</script>
<style lang="scss" scoped>
.win-input-number {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 60px;
  font-size: 28px;
  border: 1px solid var(--warning);
  cursor: pointer;
}

.win-input-number.active, .win-input-number:hover {
  background: var(--warning);
}
</style>
