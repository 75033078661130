<template>
  <div>
    <div style="font-weight: 500;">เลขที่มีการปรับอัตราจ่าย</div>
    <small class="text-danger">*** ท่านสามารถตรวจสอบเรทจ่ายได้ก่อนยืนยันส่งโพย ***</small>
    <div class="card mb-3 alert-danger border-danger p-2">
      <div>
        <span style="font-weight: 500;">2 ตัวบน:</span> {{twoNumberTop}}
        <hr class="my-1">
      </div>
      <div>
        <span style="font-weight: 500;">2 ตัวล่าง:</span> {{twoNumberBottom}}
        <hr class="my-1">
      </div>
      <div>
        <span style="font-weight: 500;">3 ตัวบน:</span> {{threeNumberTop}}
        <hr class="my-1">
      </div>
      <div>
        <span style="font-weight: 500;">3 ตัวโต๊ด:</span> {{threeNumberTode}}
        <hr class="my-1">
      </div>
      <div>
        <span style="font-weight: 500;">วิ่งบน:</span> {{runTop}}
        <hr class="my-1">
      </div>
      <div>
        <span style="font-weight: 500;">วิ่งล่าง:</span> {{runBottom}}
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import LottoHelper from '@/helpers/lotto'

export default {
  name: 'RoundStepRateNumbers',
  props: ['round', 'soldoutNumbers', 'holdings', 'stepData'],
  computed: {
    setNumbers() {
      return {
        threeNumberTop: {
          ...this.round?.setNumbers?.threeNumberTop,
          ...this.soldoutNumbers?.threeNumberTop
        },
        threeNumberTode: {
          ...this.round?.setNumbers?.threeNumberTode,
          ...this.soldoutNumbers?.threeNumberTode
        },
        twoNumberTop: {
          ...this.round?.setNumbers?.twoNumberTop,
          ...this.soldoutNumbers?.twoNumberTop
        },
        twoNumberBottom: {
          ...this.round?.setNumbers?.twoNumberBottom,
          ...this.soldoutNumbers?.twoNumberBottom
        },
        runTop: {
          ...this.round?.setNumbers?.runTop,
          ...this.soldoutNumbers?.runTop
        },
        runBottom: {
          ...this.round?.setNumbers?.runBottom,
          ...this.soldoutNumbers?.runBottom
        }
      }
    },
    twoNumberTop() {
      if(!Object.values(this.stepData?.twoNumberTop ?? {}).filter(v=>v.minReceive>0).length)
        return '-'

      const setNumbers = Object.values(this.holdings?.twoNumberTop ?? {})
      .reduce((lotsNumbers, lot)=>{
        return Object.keys(lot).reduce((lotNumbers, number)=>{

          if(this.setNumbers?.twoNumberTop?.[number])
            return lotNumbers

          const cutRate = LottoHelper.cutRate('twoNumberTop', this.round, this.holdings, this.stepData, number, 100)
          if(cutRate > 0) {
            lotNumbers.push(number)
          }
          return lotNumbers
        }, lotsNumbers)
      }, [])

      if(!setNumbers.length)
        return '-'

      return _.sortBy(setNumbers, num => parseInt(num, 10)).join(' ')
    },
    twoNumberBottom() {
      if(!Object.values(this.stepData?.twoNumberBottom ?? {}).filter(v=>v.minReceive>0).length)
        return '-'

      const setNumbers = Object.values(this.holdings?.twoNumberBottom ?? {})
      .reduce((lotsNumbers, lot)=>{
        return Object.keys(lot).reduce((lotNumbers, number)=>{

          if(this.setNumbers?.twoNumberBottom?.[number])
            return lotNumbers

          const cutRate = LottoHelper.cutRate('twoNumberBottom', this.round, this.holdings, this.stepData, number, 100)
          if(cutRate > 0) {
            lotNumbers.push(number)
          }
          return lotNumbers
        }, lotsNumbers)
      }, [])

      if(!setNumbers.length)
        return '-'

      return _.sortBy(setNumbers, num => parseInt(num, 10)).join(' ')
    },
    threeNumberTop() {
      if(!Object.values(this.stepData?.threeNumberTop ?? {}).filter(v=>v.minReceive>0).length)
        return '-'

      const setNumbers = Object.values(this.holdings?.threeNumberTop ?? {})
      .reduce((lotsNumbers, lot)=>{
        return Object.keys(lot).reduce((lotNumbers, number)=>{

          if(this.setNumbers?.threeNumberTop?.[number])
            return lotNumbers

          const cutRate = LottoHelper.cutRate('threeNumberTop', this.round, this.holdings, this.stepData, number, 100)
          if(cutRate > 0) {
            lotNumbers.push(number)
          }
          return lotNumbers
        }, lotsNumbers)
      }, [])

      if(!setNumbers.length)
        return '-'

      return _.sortBy(setNumbers, num => parseInt(num, 10)).join(' ')
    },
    threeNumberTode() {
      if(!Object.values(this.stepData?.threeNumberTode ?? {}).filter(v=>v.minReceive>0).length)
        return '-'

      const setNumbers = Object.values(this.holdings?.threeNumberTode ?? {})
      .reduce((lotsNumbers, lot)=>{
        return Object.keys(lot).reduce((lotNumbers, number)=>{

          if(this.setNumbers?.threeNumberTode?.[number])
            return lotNumbers

          const cutRate = LottoHelper.cutRate('threeNumberTode', this.round, this.holdings, this.stepData, number, 100)
          if(cutRate > 0) {
            lotNumbers.push(number)
          }
          return lotNumbers
        }, lotsNumbers)
      }, [])

      if(!setNumbers.length)
        return '-'

      return _.sortBy(setNumbers, num => parseInt(num, 10)).join(' ')
    },
    runTop() {
      if(!Object.values(this.stepData?.runTop ?? {}).filter(v=>v.minReceive>0).length)
        return '-'

      const setNumbers = Object.values(this.holdings?.runTop ?? {})
      .reduce((lotsNumbers, lot)=>{
        return Object.keys(lot).reduce((lotNumbers, number)=>{

          if(this.setNumbers?.runTop?.[number])
            return lotNumbers

          const cutRate = LottoHelper.cutRate('runTop', this.round, this.holdings, this.stepData, number, 100)
          if(cutRate > 0) {
            lotNumbers.push(number)
          }
          return lotNumbers
        }, lotsNumbers)
      }, [])

      if(!setNumbers.length)
        return '-'

      return _.sortBy(setNumbers, num => parseInt(num, 10)).join(' ')
    },
    runBottom() {
      if(!Object.values(this.stepData?.runBottom ?? {}).filter(v=>v.minReceive>0).length)
        return '-'

      const setNumbers = Object.values(this.holdings?.runBottom ?? {})
      .reduce((lotsNumbers, lot)=>{
        return Object.keys(lot).reduce((lotNumbers, number)=>{

          if(this.setNumbers?.runBottom?.[number])
            return lotNumbers

          const cutRate = LottoHelper.cutRate('runBottom', this.round, this.holdings, this.stepData, number, 100)
          if(cutRate > 0) {
            lotNumbers.push(number)
          }
          return lotNumbers
        }, lotsNumbers)
      }, [])

      if(!setNumbers.length)
        return '-'

      return _.sortBy(setNumbers, num => parseInt(num, 10)).join(' ')
    }
  }
}
</script>
